import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import qs from 'query-string'
import {
  GET_APPEALS_TABLE,
  APPEALS_TABLE,
  SET_SELECTED_ELEMENT,
} from '@/store/actions/serviceDeskMyCity'
import moment from 'moment'
import { SERVICE_DESK_GET_APPEALS_URL } from '@/constants/apiRoutes'
import {
  successAppealsTable,
  errorAppealsTable,
} from '@/store/actions/serviceDeskMyCity/getTable'
import {
  getTableParameters,
} from '@/store/selectors/serviceDeskMyCity'
import {
  OBJECT,
  OBJECT_TYPE,
  TASK_STATUS,
  PLANNED_DUE_DATE,
  ACTUAL_DATE_OF_EXECUTION,
  EXECUTION_EXPIRED,
  TASK,
  APPEALS_BLOCK,
  OBJECT_TYPE_BLOCK,
} from '@/constants/serviceDeskConfig/nameConfig'
import {
  getIntegrationStatus,
  getUserApplications,
  getUserData,
} from '@/store/selectors/appSettings'
import {
  getRouterLocation,
} from '@/store/selectors/router'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { statusSD } from '@/forms/ServiceDeskMyCityForm/config'

function* getServiceDeskTableSaga({ payload }) {
  yield put(setGlobalLoading(true))
  const { urlParse, serviceDeskId, serviceDeskConfig } = payload
  const integrationStatus = yield select(getIntegrationStatus)
  const userApplications = yield select(getUserApplications)
  const currentLocation = yield select(getRouterLocation)
  const userData = yield select(getUserData)
  const currentQuery = urlParse || qs.parse(currentLocation.search)
  const currentServiceDesk = integrationStatus.find(
    (element) => (
      userData.roles.isSuperAdmin
        ? currentQuery.widgetId === element.temporaryId
        : currentQuery.widgetId === element.widgetId
    ),
  )
  const currentServiceDeskConfig = serviceDeskConfig || currentServiceDesk.propertyConfigContent
  const fields = {
    essenceOfAppeal: 'DETAIL_TEXT',
    registrationDate: 'DATE_CREATE',
    search: '%25DETAIL_TEXT',
    object: `PROPERTY_${currentServiceDeskConfig[OBJECT].id}`,
    objectType: `PROPERTY_${currentServiceDeskConfig[OBJECT_TYPE].id}`,
    status: `PROPERTY_${currentServiceDeskConfig[TASK_STATUS].id}`,
    plannedExecutionDate: `PROPERTY_${currentServiceDeskConfig[PLANNED_DUE_DATE].id}`,
    actualDateOfExecution: `PROPERTY_${currentServiceDeskConfig[ACTUAL_DATE_OF_EXECUTION].id}`,
    startDate: '%3E%3DDATE_CREATE',
    endDate: '%3C%3DDATE_CREATE',
    overdue: `PROPERTY_${currentServiceDeskConfig[EXECUTION_EXPIRED].id}`,
  }
  const serviceDesk = userApplications.find((application) => application.code === SERVICE_DESK)
  const selectedServiceDeskId = serviceDeskId
    || (currentServiceDesk && currentServiceDesk.id)
    || (serviceDesk && JSON.parse(serviceDesk.settings).id)
  const urlKeys = currentQuery ? Object.keys(currentQuery).map((element) => fields[element]) : []
  const urlValues = currentQuery ? Object.values(currentQuery) : []

  // Отдельно обрабатываем фильтр по типу объекта
  let objectTypeParams = ''
  const objectTypeIndex = urlKeys.findIndex(key => key === fields.objectType)
  if (objectTypeIndex !== -1) {
    // Удаляем параметр типа объекта из основных параметров
    urlKeys.splice(objectTypeIndex, 1)
    const objectTypeValue = urlValues.splice(objectTypeIndex, 1)[0]
    // Формируем отдельный запрос для фильтрации по типу объекта
    objectTypeParams = `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${currentServiceDeskConfig[OBJECT_TYPE].idInfoBlock}&FILTER[PROPERTY_${currentServiceDeskConfig[OBJECT_TYPE].id}]=${objectTypeValue}`
  }

  // Формируем остальные параметры фильтрации
  const urlParams = urlKeys.map((element, index) => {
    if (element === fields.overdue && urlValues[index] === 'true') {
      return `FILTER[${element}]=1`
    }
    if (element === fields.overdue && urlValues[index] === 'false') {
      return 'false'
    }
    if (element === fields.startDate) {
      return `FILTER[${element}]=${moment(urlValues[index]).format('YYYY-MM-DDTHH:mm:ss.SSS')}%2B03:00`
    }
    if (element === fields.endDate) {
      return `FILTER[${element}]=${moment(urlValues[index]).format('YYYY-MM-DDTHH:mm:ss.SSS')}%2B03:00`
    }
    if (element === fields.status && urlValues[index] === 'waitingExecution') {
      return `FILTER[${element}][0]=registered&FILTER[${element}][1]=contractorAppointed`
    }
    if (element) {
      return `FILTER[${element}]=${urlValues[index]}`
    }
    return ''
  }).join('&')

  try {
    const tableParameters = yield select(getTableParameters)

    // Если есть фильтр по типу объекта
    let filteredAppealIds = []
    let totalObjects = 0
    if (objectTypeParams) {
      // Добавляем параметры фильтрации по дате к параметрам запроса объектов
      const dateParams = urlKeys
        .map((element, index) => {
          if (element === '%3E%3DDATE_CREATE') {  // используем прямое сравнение вместо fields.startDate
            return `FILTER[DATE_CREATE]=${moment(urlValues[index]).format('YYYY-MM-DDTHH:mm:ss.SSS')}%2B03:00`
          }
          if (element === '%3C%3DDATE_CREATE') {  // используем прямое сравнение вместо fields.endDate
            return `FILTER[DATE_CREATE]=${moment(urlValues[index]).format('YYYY-MM-DDTHH:mm:ss.SSS')}%2B03:00`
          }
          return null
        })
        .filter(Boolean)
        .join('&')

      const currentPageStart = (tableParameters.page - 1) * 50
      const fullObjectParams = `${objectTypeParams}${dateParams ? `&${dateParams}` : ''}&ELEMENT_ORDER[ID]=desc&start=${currentPageStart}`

      // Сначала получаем первую страницу для получения total
      const firstPageObjects = yield request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: {
          integrationId: selectedServiceDeskId,
          parameters: fullObjectParams,
          restMethod: '/lists.element.get.json',
        },
      })
      
      totalObjects = firstPageObjects.data.total
      
      // Получаем объекты для текущей страницы с теми же параметрами фильтрации
      const filteredObjects = yield request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: {
          integrationId: selectedServiceDeskId,
          parameters: fullObjectParams,
          restMethod: '/lists.element.get.json',
        },
      })

      // Получаем ID обращений из PROPERTY_490 найденных объектов
      filteredAppealIds = filteredObjects.data.result
        .map(obj => obj[`PROPERTY_${currentServiceDeskConfig[OBJECT].linkProperty}`] ? Object.values(obj[`PROPERTY_${currentServiceDeskConfig[OBJECT].linkProperty}`])[0] : null)
        .filter(Boolean)
    }

    // Получаем обращения с учетом всех фильтров
    const appealsTable = yield request({
      url: SERVICE_DESK_GET_APPEALS_URL,
      method: 'post',
      body: {
        integrationId: selectedServiceDeskId,
        parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${currentServiceDeskConfig[APPEALS_BLOCK].idInfoBlock}&start=${(tableParameters.page - 1) * 50}&ELEMENT_ORDER[${fields[tableParameters.sort.field]}]=${tableParameters.sort.direction}${urlParams ? `&${urlParams}` : ''}${filteredAppealIds.length > 0 ? `&${filteredAppealIds.map((id, index) => `FILTER[ID][${index}]=${id}`).join('&')}` : ''}&ELEMENT_ORDER[ID]=desc`,
        restMethod: '/lists.element.get.json',
      },
    })

    // Инициализируем пустые результаты для объектов и типов
    let objects = { data: { result: [] } }
    let objectTypes = { data: { result: [] } }

    // Пытаемся получить дополнительные данные (объекты и типы)
    try {
      // Получаем справочник типов объектов
      objectTypes = yield request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: {
          integrationId: selectedServiceDeskId,
          parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${currentServiceDeskConfig[OBJECT_TYPE_BLOCK].idInfoBlock}&ELEMENT_ORDER[ID]=desc`,
          restMethod: '/lists.element.get.json',
        },
      })

      // Получаем ID обращений для фильтрации объектов
      const appealIds = appealsTable.data.result.map(element => element.ID).filter(Boolean)
      if (appealIds.length > 0) {
        const filterParams = appealIds
          .map((id, index) => `FILTER[PROPERTY_${currentServiceDeskConfig[OBJECT].linkProperty}][${index}]=${id}`)
          .join('&')

        // Получаем объекты, связанные с обращениями
        objects = yield request({
          url: SERVICE_DESK_GET_APPEALS_URL,
          method: 'post',
          body: {
            integrationId: selectedServiceDeskId,
            parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${currentServiceDeskConfig[OBJECT_TYPE].idInfoBlock}&ELEMENT_ORDER[ID]=desc&${filterParams}`,
            restMethod: '/lists.element.get.json',
          },
        })
      }
    } catch (error) {
      console.error('Error fetching additional data:', error)
      // Продолжаем выполнение с пустыми объектами и типами
    }

    // Формируем контент независимо от результата запросов дополнительных данных
    const content = appealsTable.data.result.map((element) => {
      // Ищем объект, связанный с текущим обращением через PROPERTY_490
      const foundObject = objects.data.result.find((item) => 
        item[`PROPERTY_${currentServiceDeskConfig[OBJECT].linkProperty}`] && String(Object.values(item[`PROPERTY_${currentServiceDeskConfig[OBJECT].linkProperty}`])[0]) === String(element.ID)
      )

      // Получаем тип объекта если объект найден
      const objectType = (() => {
        if (!foundObject) return ''
        
        const typeId = foundObject[`PROPERTY_${currentServiceDeskConfig[OBJECT_TYPE].id}`]
          ? Object.values(foundObject[`PROPERTY_${currentServiceDeskConfig[OBJECT_TYPE].id}`])[0]
          : null
        
        return typeId && objectTypes.data.result.length > 0
          ? objectTypes.data.result.find(type => type.ID === typeId)?.NAME || '-'
          : ''
      })()

      return {
        essenceOfAppeal: element.DETAIL_TEXT,
        registrationDate: element.DATE_CREATE,
        object: foundObject?.NAME || '',
        objectType,
        status: element[`PROPERTY_${currentServiceDeskConfig[TASK_STATUS].id}`] 
          ? statusSD[Object.values(element[`PROPERTY_${currentServiceDeskConfig[TASK_STATUS].id}`])[0]]
          : '',
        plannedExecutionDate: element[`PROPERTY_${currentServiceDeskConfig[PLANNED_DUE_DATE].id}`] ? Object.values(element[`PROPERTY_${currentServiceDeskConfig[PLANNED_DUE_DATE].id}`])[0] : '',
        actualDateOfExecution: element[`PROPERTY_${currentServiceDeskConfig[ACTUAL_DATE_OF_EXECUTION].id}`] ? Object.values(element[`PROPERTY_${currentServiceDeskConfig[ACTUAL_DATE_OF_EXECUTION].id}`])[0] : '',
        overdue: element[`PROPERTY_${currentServiceDeskConfig[EXECUTION_EXPIRED].id}`] ? +Object.values(element[`PROPERTY_${currentServiceDeskConfig[EXECUTION_EXPIRED].id}`])[0] : null,
        taskNumber: element[`PROPERTY_${currentServiceDeskConfig[TASK].id}`] ? Object.values(element[`PROPERTY_${currentServiceDeskConfig[TASK].id}`])[0] : '',
        id: element.ID,
      }
    })

    // Формируем список типов для выпадающего списка
    const newTypeOfObject = objectTypes.data.result.map((element) => ({
      value: element.ID,
      title: element.NAME,
    }))

    yield put(setGlobalLoading(false))
    yield put(successAppealsTable({
      data: content,
      totalElements: objectTypeParams ? totalObjects : appealsTable.data.total,
      typeOfObjects: newTypeOfObject,
    }))
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorAppealsTable(error))
  }
}

export default function* root() {
  yield takeLatest(GET_APPEALS_TABLE.REQUEST, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.SORT, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.PER_PAGE, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.PAGE, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.SELECTED_FIELDS, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.REQUEST_QUERY, getServiceDeskTableSaga)
  yield takeLatest(SET_SELECTED_ELEMENT, getServiceDeskTableSaga)
}
