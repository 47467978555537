import AverageLeadTimeIcon from './AverageLeadTimeIcon'
import DoneIcon from './DoneIcon'
import OverdueOnExecutionIcon from './OverdueOnExecutionIcon'
import ReceivedIcon from './ReceivedIcon'
import TasksPerformedIcon from './TasksPerformedIcon'
import WaitingForControlIcon from './WaitingForControlIcon'
import UploadFileIcon from './UploadFileIcon'
import PlusIcon from './PlusIcon'
import RegisteredIcon from './RegisteredIcon'
import InWorkIcon from './InWorkIcon'
import SolvedIcon from './SolvedIcon'
import OverdueIcon from './OverdueIcon'
import DelayedIcon from './DelayedIcon'
import WaitingExecutionIcon from './WaitingExecutionIcon'
import WaitingExecutionCardIcon from './WaitingExecutionCardIcon'
import AverageTimeDecisionIcon from './AverageTimeDecisionIcon'

export default {
  AverageLeadTimeIcon,
  DoneIcon,
  OverdueOnExecutionIcon,
  ReceivedIcon,
  TasksPerformedIcon,
  WaitingForControlIcon,
  UploadFileIcon,
  PlusIcon,
  RegisteredIcon,
  InWorkIcon,
  SolvedIcon,
  OverdueIcon,
  DelayedIcon,
  WaitingExecutionIcon,
  WaitingExecutionCardIcon,
  AverageTimeDecisionIcon,
}
