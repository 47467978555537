import isEmpty from 'lodash/isEmpty'
import {
  RECEIVED,
  AVERAGE_LEAD_TIME,
  UNREGISTERED,
} from '@/constants/serviceDeskMyCity'
import { contentStructure } from '@/constants/serviceDeskWidget'
import {
  ACTUAL_DATE_OF_EXECUTION,
} from '@/constants/serviceDeskConfig/nameConfig'

export const convertDate = (date) => {
  const regExpWithTime = /(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2}):(\d{2})/
  const regExpOnlyDate = /(\d{2}).(\d{2}).(\d{4})/
  const arrayWithTime = regExpWithTime.exec(date)
  const arrayOnlyDate = regExpOnlyDate.exec(date)
  if (arrayWithTime) {
    return new Date(
      (+arrayWithTime[3]),
      (+arrayWithTime[2]) - 1,
      (+arrayWithTime[1]),
      (+arrayWithTime[4]),
      (+arrayWithTime[5]),
      (+arrayWithTime[6]),
    )
  }
  return new Date(
    (+arrayOnlyDate[3]),
    (+arrayOnlyDate[2]) - 1,
    (+arrayOnlyDate[1]),
  )
}

export const calculatingAverageTime = (firstDate, secondDate, countOfElements) => {
  const milliseconds = 1000
  const seconds = 60
  const minutes = 60
  const timeDifference = convertDate(firstDate) - convertDate(secondDate)
  return ((timeDifference) / (milliseconds * seconds * minutes)) / countOfElements
}

export const calculateAverageDays = (numberOfHours) => {
  if (!numberOfHours) {
    return undefined
  }
  const hours = 24
  const minutes = 60
  const daysCount = Math.floor(numberOfHours / hours)
  const rest= numberOfHours % hours;
  const hoursCount= Math.floor(rest);
  const minutesCount = Math.floor(minutes * (rest - hoursCount))
  return {
    days: daysCount,
    hours: minutesCount > 30 ? hoursCount + 1 : hoursCount
  }
}

export const getAverageDays = (arrayTime, config) => {
  const type = `PROPERTY_${config[ACTUAL_DATE_OF_EXECUTION].id}`
  const arrayOfAverageTime = arrayTime.map((element) => (
    element.DATE_CREATE && element[type]
      ? calculatingAverageTime(
        Object.values(element[type])[0],
        element.DATE_CREATE,
        arrayTime.length,
      )
      : 0
  ))
  if (isEmpty(arrayOfAverageTime)) {
    return 0
  }
  const averageCompletionTime = arrayOfAverageTime.reduce((previousValue, currentValue) => (
      previousValue + currentValue
  ))

  return calculateAverageDays(averageCompletionTime)
}

export const getWidgetContent = (response, config) => {
  const sumOfAverageTime = getAverageDays(response[RECEIVED].data.result, config)

  const widgetContent = contentStructure.map((element) => {
    if (element.id === AVERAGE_LEAD_TIME) {
      return {
        ...element,
        value: sumOfAverageTime,
      }
    }

    if (element.id === RECEIVED) {
      const receivedCount = response[RECEIVED].data.total || 0
      const unregisteredCount = response[UNREGISTERED].data.total || 0
      return {
        ...element,
        value: Math.max(0, receivedCount - unregisteredCount),
      }
    }

    return {
      ...element,
      value: response[element.id].data.total || 0,
    }
  })
  return widgetContent
}
