import {
  takeEvery, put, all, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SERVICE_DESK_GET_APPEALS_URL } from '@/constants/apiRoutes'
import { GET_SERVICE_DESK_INFO_WIDGET } from '@/store/actions/serviceDeskMyCity'
import { successGetInfoWidget } from '@/store/actions/serviceDeskMyCity/getInfoWidget'
import { getIntegrationStatus, getUserApplications, getUserData, getUserSettings } from '@/store/selectors/appSettings'
import { getWidgetContent } from '@/helpers/serviceDeskHelpers'
import {
  RECEIVED,
  DONE,
  TASKS_PERFORMED,
  WAITING_FOR_CONTROL,
  WAITING_FOR_EXECUTION,
  OVERDUE_ON_EXECUTION,
  ALL_TIME,
  UNREGISTERED,
} from '@/constants/serviceDeskMyCity'
import serviceDeskWidgetStructure from '@/constants/serviceDeskWidget'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import {
  TASK_STATUS,
  TASK,
  EXECUTION_EXPIRED,
  APPEALS_BLOCK,
} from '@/constants/serviceDeskConfig/nameConfig'
import createNotifications from '@/helpers/notification'

const bodyGenerator = (serviceDeskConfig, id, parameters) => ({
  integrationId: id,
  parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${serviceDeskConfig[APPEALS_BLOCK].idInfoBlock}&start=0${parameters || ''}`,
  restMethod: '/lists.element.get.json',
})

function formatTodayDate(date) {
  const dd = '01'
  let mm = date.getMonth() + 1
  if (mm < 10) mm = `0${mm}`
  const yy = date.getFullYear()
  return `${yy}-${mm}-${dd}`
}

function* getServiceDeskWidgetSaga({ payload }) {
  const { id } = payload
  const todayDate = new Date()
  try {
    const integrationStatus = yield select(getIntegrationStatus)
    const userData = yield select(getUserData)
    const userApplications = yield select(getUserApplications)
    const userSettings = yield select(getUserSettings)
    const serviceDesk = userApplications.find((application) => application.code === SERVICE_DESK)
    const currentIntegrationStatus = integrationStatus.find(
      (status) => (
        userData.roles.isSuperAdmin
          ? id === status.temporaryId
          : id === status.widgetId
      ),
    )
    const serviceDeskId = integrationStatus
      ? currentIntegrationStatus.id
      : JSON.parse(serviceDesk.settings).id
    const serviceDeskConfig = userSettings.serviceDeskIntegrationStatus.filter(item => item.id === serviceDeskId)[0].propertyConfigContent || []
    const responseAllTime = yield all({
      [RECEIVED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId),
      }),
      [UNREGISTERED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=&!FILTER[PROPERTY_${serviceDeskConfig[TASK].id}]`),
      }),
      [DONE]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=completed`),
      }),
      [TASKS_PERFORMED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=inProgress`),
      }),
      [WAITING_FOR_CONTROL]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=waitingControl`),
      }),
      [WAITING_FOR_EXECUTION]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}][0]=registered&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}][1]=contractorAppointed`),
      }),
      [OVERDUE_ON_EXECUTION]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[EXECUTION_EXPIRED].id}]=1`),
      }),
    })
    const responseCurrentMonth = yield all({
      [RECEIVED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[%3E%3DDATE_CREATE]=${formatTodayDate(todayDate)}`),
      }),
      [UNREGISTERED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=&!FILTER[PROPERTY_${serviceDeskConfig[TASK].id}]&FILTER[%3E%3DDATE_CREATE]=${formatTodayDate(todayDate)}`),
      }),
      [DONE]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=completed&FILTER[%3E%3DDATE_CREATE]=${formatTodayDate(todayDate)}`),
      }),
      [TASKS_PERFORMED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=inProgress&FILTER[%3E%3DDATE_CREATE]=${formatTodayDate(todayDate)}`),
      }),
      [WAITING_FOR_CONTROL]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=waitingControl&FILTER[%3E%3DDATE_CREATE]=${formatTodayDate(todayDate)}`),
      }),
      [WAITING_FOR_EXECUTION]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}][0]=registered&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}][1]=contractorAppointed&FILTER[%3E%3DDATE_CREATE]=${formatTodayDate(todayDate)}`),
      }),
      [OVERDUE_ON_EXECUTION]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[EXECUTION_EXPIRED].id}]=1&FILTER[%3E%3DDATE_CREATE]=${formatTodayDate(todayDate)}`),
      }),
    })
    const allTimeContent = getWidgetContent(responseAllTime, serviceDeskConfig)
    const currentMonthContent = getWidgetContent(responseCurrentMonth, serviceDeskConfig)

    const widgetStructure = serviceDeskWidgetStructure.map((element) => ({
      ...element,
      content: element.id === ALL_TIME ? allTimeContent : currentMonthContent,
    }))
    yield put(successGetInfoWidget({
      [id]: [
        ...widgetStructure,
      ],
    }))
  } catch (error) {
    console.log('TCL: function*getServiceDeskWidgetSaga -> error', error)
    const toast = createNotifications()
    if (error.response.data.error === 'ACCESS_DENIED') {
      toast({
        title: 'Ошибка операции!',
        description: 'Нет прав для работы с функционалом',
        type: 'error',
      })
    }
    yield put(successGetInfoWidget({
      [id]: [],
    }))
  }
}

export default function* root() {
  yield takeEvery(GET_SERVICE_DESK_INFO_WIDGET.REQUEST, getServiceDeskWidgetSaga)
}
