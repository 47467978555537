export default {
  mainTitle: 'Сервис Деск «Мой город»',
  statistics: 'Статистика',
  appeals: 'Обращения',
  overdue: 'Просрочено',
  resetFilter: 'Сбросить фильтр',
  find: 'Найти',
  build: 'Построить',
  objectType: 'Тип объекта',
  status: 'Статус',
  statusSD: {
    notViewed: 'Новая задача (не просмотрена)',
    new: 'Новая задача',
    registered: 'Зарегистрировано',
    contractorAppointed: 'Назначен исполнитель',
    waitingExecution: 'Ждет выполнения',
    inProgress: 'Задача выполняется',
    waitingControl: 'Ждет контроля',
    completed: 'Исполнено',
    rejected: 'Задача отклонена ответственным',
    almostExpired: 'Задача почти просрочена',
    expired: 'Задача просрочена',
    inWork: 'В работе',
    resolved: 'Решено',
    delayed: 'Отложено',
    executionTime: 'Среднее время исполнения',
  }
}
