import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WaitingExecutionCardIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="100%" height="100%" viewBox="0 0 53 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.05" transform="rotate(338 26 24) translate(3 5)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.35714 0H43.6428L43.6481 7.48267e-06C45.8612 0.00626641 47.9819 0.882994 49.5468 2.43865C51.1117 3.9943 51.9937 6.10242 52 8.30244L52 8.30769L52 30.4615L52 30.4668C51.9937 32.6668 51.1117 34.7749 49.5468 36.3306C47.9819 37.8862 45.8612 38.7629 43.6481 38.7692L43.6428 38.7692H24.8098L14.1893 47.5718C13.6357 48.0306 12.8652 48.1296 12.2125 47.8258C11.5598 47.522 11.1429 46.8702 11.1429 46.1538V38.7692H8.35714L8.35186 38.7692C6.13874 38.7629 4.01808 37.8862 2.45316 36.3306C0.88825 34.7749 0.00630371 32.6668 7.52721e-06 30.4668L0 30.4615V8.30244C0.00629618 6.10242 0.88825 3.9943 2.45316 2.43865C4.01808 0.882994 6.13875 0.00626641 8.35186 7.48267e-06L8.35714 0ZM43.64 3.69231H8.36C7.12919 3.69642 5.94993 4.18428 5.07956 5.0495C4.20919 5.91472 3.71843 7.087 3.71429 8.31053V30.4587C3.71843 31.6822 4.20919 32.8545 5.07956 33.7197C5.94996 34.585 7.12927 35.0728 8.36013 35.0769H13C14.0257 35.0769 14.8571 35.9035 14.8571 36.9231V42.2109L22.6877 35.7207C23.1885 35.3054 23.8214 35.0769 24.4737 35.0769H43.6399C44.8707 35.0728 46.05 34.585 46.9204 33.7197C47.7908 32.8545 48.2816 31.6821 48.2857 30.4586V8.31066C48.2816 7.08709 47.7908 5.91475 46.9204 5.0495C46.0501 4.18428 44.8708 3.69642 43.64 3.69231Z" fill="black"/>
        <path d="M23.6179 12.7485C23.6179 10.6617 22.3105 8.79458 20.3494 8.13559V12.1993H17.081V8.13559C15.1199 8.79458 13.8125 10.6617 13.8125 12.7485C13.8125 14.8353 15.1199 16.7024 17.081 17.3614V29.4427C17.081 29.882 17.2989 30.1017 17.6257 30.1017H19.8047C20.1315 30.1017 20.3494 29.882 20.3494 29.5525V17.4712C22.3105 16.8122 23.6179 14.9451 23.6179 12.7485ZM30.1548 14.7254C25.9058 14.8353 22.5284 18.24 22.5284 22.4136C22.5284 26.6969 25.9058 30.1017 30.1548 30.1017C34.4038 30.1017 37.7812 26.6969 37.7812 22.4136C37.7812 18.1302 34.4038 14.7254 30.1548 14.7254ZM30.1548 27.9051C27.1043 27.9051 24.7074 25.4888 24.7074 22.4136C24.7074 19.3383 27.1043 16.922 30.1548 16.922C33.2054 16.922 35.6023 19.3383 35.6023 22.4136C35.6023 25.4888 33.2054 27.9051 30.1548 27.9051ZM29.0653 18.0203V23.5119L32.9875 25.9281L33.8591 24.6102L30.6996 22.743V18.0203H29.0653Z" fill="black"/>
      </g>
    </svg>
  )
}

WaitingExecutionCardIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WaitingExecutionCardIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WaitingExecutionCardIcon)
