import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WaitingExecutionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.49988 4.64994C7.49988 3.22494 6.59988 1.94994 5.24988 1.49994V4.27494H2.99988V1.49994C1.64988 1.94994 0.749878 3.22494 0.749878 4.64994C0.749878 6.07494 1.64988 7.34994 2.99988 7.79994V16.0499C2.99988 16.3499 3.14988 16.4999 3.37488 16.4999H4.87488C5.09988 16.4999 5.24988 16.3499 5.24988 16.1249V7.87494C6.59988 7.42494 7.49988 6.14994 7.49988 4.64994ZM11.9999 5.99994C11.9249 5.99994 11.9999 5.99994 11.9999 5.99994C9.07488 6.07494 6.74988 8.39994 6.74988 11.2499C6.74988 14.1749 9.07488 16.4999 11.9999 16.4999C14.9249 16.4999 17.2499 14.1749 17.2499 11.2499C17.2499 8.32494 14.9249 5.99994 11.9999 5.99994ZM11.9999 14.9999C9.89988 14.9999 8.24988 13.3499 8.24988 11.2499C8.24988 9.14994 9.89988 7.49994 11.9999 7.49994C14.0999 7.49994 15.7499 9.14994 15.7499 11.2499C15.7499 13.3499 14.0999 14.9999 11.9999 14.9999ZM11.2499 8.24994V11.9999L13.9499 13.6499L14.5499 12.7499L12.3749 11.4749V8.24994H11.2499Z" fill="black"/>
    </svg>

  )
}

WaitingExecutionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WaitingExecutionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WaitingExecutionIcon)
