import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'
import {
  DANGER,
  NORMAL,
} from '@/constants/semanticNames'


export const StyledTooltip = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export const ContainerLink = styled.div`
  ${({ disabled, theme }) => disabled === null && `
    opacity: 0.3;
    ${theme.themeName === LIGHT_THEME_NAME && 'pointer-events: none;'}
  `}
  background: ${({ background }) => background};
  width: 216px;
  height: 120px;
  font-size: 1.4rem;
  border-radius: 10px;
  margin: 7.5px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  > svg {
    height: 50px;
  }
  * {
    text-decoration: none;
  }

  a > {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background: ${({ theme }) => theme.themeName === LIGHT_THEME_NAME
      && theme.colors.scheme.backgrounds.default};
  }
  ${({ isBig }) => isBig && `
    width: 293px;
  `}

`
export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100% - 42px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // ${({ isBig }) => isBig && `
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-around;
  //   align-items: center;
  // `}
  svg {
    width: 24px;
    height: 24px;
  }
`

export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
`

export const Count = styled.span`
  font-size: 4.8rem;
  line-height: 5.6rem;
  text-align: center;
`

export const ListContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`

export const RowWrapper = styled.div`
  width: 100%;
  text-align: right;
  padding-right: 10px;
  margin-bottom: 5px;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  ${({ md }) => md && `
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.09rem;
  `}
  ${({ bf }) => bf && `
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;
  `}
  ${ContainerLink}:hover && {
    ${({ theme, highLight }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      if (highLight === DANGER) {
        return `color: ${theme.colors.messages.error};`
      }
      if (highLight === NORMAL) {
        return `color: ${theme.colors.backgrounds.redisign.light};`
      }
    }
  }}
  }
`

export const FieldsContainer = styled.div`
  min-width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${Title} {
    text-align: left;
  }
`

export const Notification = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  background: ${({ theme }) => theme.gradients.normal};
  ${ContainerLink}:hover && {
    border-radius: 0px 0px 3px 3px;
  }
  ${({ theme, type }) => type === 'alarm' && `
    background: ${theme.gradients.alarm}
    ${theme.animations.alarm.connect(2500)}
  `}
`
export const MainTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  width: 100%;
  text-align: left;
`

export const TitleContainer = styled.div`
  width: 100%;
  min-height: 42px;
  display: flex;
  align-items: center
  justify-content: flex-start;
  padding: 0 5px 0 10px;
  gap: 10px;
`

export const IconWrapper = styled.div`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
`
